import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/layout";
import SEO from "../components/seo";
import icon from "../images/icon-partners.svg";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';



const Partners = ({ data }) => {
  const Partners = data.allContentfulPartner.edges;
  return (
    <Layout>
      <SEO title="Lawtech Hub Partners" />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header image"
        />
      </div>
      <div className="container">
      <h1 className="title">Partners</h1>
      </div>
      <div className="bg-white w-full border-t-2 border-b border-white">
        <div className="container flex py-6 my-2 self-center">
          <div className="w-auto lg:w-1/2 self-center">
            <h3 className="pt-6 text-center md:text-left">
              {documentToReactComponents(data.contentfulPageIntro.intro.json)}
            </h3>
          </div>
          <div className="w-1/2 hidden lg:block">
            <img src={icon} className="h-64 mb-0 float-right mx-12" alt="LawTech Hub Icons" />
          </div>
        </div>
      </div>




      <div className="container mt-12 mb-32">

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 -mx-2 mt-24">
          {Partners.map(({ node: post }) => (


            <Link to={`/partners/${post.slug}`} key={post.id} className="hover-card flex bg-white shadow-sm mb-12 mx-2 rounded-sm">

              <div className="relative px-8 pb-8 pt-10 text-xs text-center leading-4 w-full">

                <div className="relative mx-auto h-24 w-24 -mt-20 border-gray-100 flex  rounded-full border-4 bg-white justify-center items-center">
                  <Img
                    className="logo block"
                    fixed={post.logo.fixed}
                    title={post.companyName}
                    alt={post.companyName}

                  />
                </div>
                <div className="text-sm font-extrabold text-purple-800 mt-4 mb-2">{post.companyName}</div>
                <div className="font-bold text-black my-2">{post.serviceType}</div>
                <div className="my-8 leading-5"                   
                dangerouslySetInnerHTML={{
                  __html: post.offering.childMarkdownRemark.html,
                }}
             />
              </div>
            </Link>




          ))}
        </div>
      </div>



    </Layout>
  );
};
export default Partners;



export const query = graphql`
  query partnersIndexPageQuery {
    contentfulPageIntro(page: { eq: "Partners" }) {
      intro {
        json
      } 
    }
    allContentfulPartner(sort: {fields: companyName, order: ASC}) {
      edges {
        node {
          id
          slug
          companyName
          serviceType
          offering {
            childMarkdownRemark {
              html
            }
          }
          logo {
            fixed(width: 80) {
              base64
              src
              srcSet
              width
              height
            }
          }
        }
      }
    }
    file(relativePath: { eq: "banner-partners.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }    
  }



`;